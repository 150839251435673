import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { styled } from 'styles';
import Modal, { ModalProps } from 'components/Modal';
import FileInput from 'components/FileInput';
import Button from 'components/Button';
import DocumentsContext from './context';

const UploadFileModal: React.FC<ModalProps> = ({ onClose, ...props }) => {
  const { uploadFile } = useContext(DocumentsContext);
  const { control, errors, handleSubmit, formState } = useForm();

  const submit = async values => {
    await uploadFile(values.file);
    if (onClose) onClose();
  };

  return (
    <StyledModal modalTitle="Upload file" onClose={onClose} {...props}>
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          name="file"
          rules={{ required: 'Required' }}
          defaultValue=""
          render={controllerProps => (
            <FileInput error={errors.file?.message} label="Select File" {...controllerProps} />
          )}
        />
        <Button type="submit" disabled={formState.isSubmitting}>
          {formState.isSubmitting ? 'Uploading...' : 'Upload'}
        </Button>
      </form>
    </StyledModal>
  );
};

export default UploadFileModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;

    form {
      .input,
      .dropdown,
      .checkbox,
      .button {
        width: 100%;
        margin: 8px 0;
      }

      .button {
        height: 40px;
      }

      & > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
